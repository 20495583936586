/* Global styles */
html,
body {
  padding: 0;
  margin: 0;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-family: Arial, Helvetica, sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#featuredPage .ui.blue.button:not(.ui.blue.button:hover) {
  background-color: #73b9f5 !important;
}

#tsparticles {
  z-index: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: white;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: #e5e7eb;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }

  .border-input {
    border: 1px solid rgb(229, 231, 235) !important;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@media screen and (max-width: 820px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }

  input#geosuggest__input::placeholder {
    font-size: 16px;
  }
}

.learn-more {
  background-image: none !important;
}

.generate {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-top: 30px;
}

.react-pdf__Page__canvas {
  width: 400px !important;
  height: auto !important;
  max-width: 90%;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}

#geosuggest__input {
  border: none;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --color-white: #f5f5f5;
  --white: rgb(247, 247, 247);
  /* --primary: #103155; */
  --primary: #2a394c;
  --lightblue-primary: #50647a;
  --accent-one: #0ba1ef;
  --accent-two: #dd9101;
  --black: #707070;
  --lightblack: #707070;
  --lightblue: #f2faff;
  --blackblack: #3e3e3e;
  --navbar-height: 80px;
  --navbar-font-size: 20px;
  --mobile-navbar-height: 60px;
  --subnav-height: 45px;
  --error-red: rgb(237, 23, 23);
  --dashboard-grey: #ededed;
  --dashboard-top-margin: 28px;
  --draft-grey: #c7c7c7;
  --black-font: rgb(49, 49, 49);
  --link-blue: #73b9f5;
  --accepted-green: #00840a;
  --declined-red: #a81e1e;
  --body-font: Arial, Helvetica, sans-serif;
  --header-font: 'Catamaran', sans-serif;
  --mobile-dialogue-header-font: normal normal 600 24px var(--header-font);
  --mobile-dialogue-subheader-font: normal normal 500 20px var(--body-font);
  --mobile-search-height: 147px;
  --color-primary-orange: #f90;
  --color-accent-blue: #148fbf;
  --avenir: 'Avenir', sans-serif;
  --pewter: #7ca5b8;
  --charcoal: #2e4057;
  --shadow: 0px 0px 5px #00000029;
  --featured-gold: #fbbd08;
  --date-posted-color: #555454;
  --auto-purple: rgb(112, 116, 242);
  --size-cube: 210px;
  --faces-interval: calc(var(--size-cube) / 2 / 5);
  --size-dot: 4px;
  --delay-anim: .3s;
  /* React Phone Number Input styles */
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);  
  --header-height: 4rem;
}

.rmsc {
  --rmsc-gray: rgb(76, 76, 76) !important;
}

#testimonials {
  width: 100vw !important;
}

.candidateApplications .cardDetail {
  margin-top: 20px;
  padding-bottom: 35px;
  background-color: white;
}

.salary-dropdown .dropdown-content {
  display: none;
}

.salary-dropdown span.gray {
  color: rgba(0, 0, 0, 0.87) !important;
}

.rmsc .select-item.selected {
  background-color: white !important;
}

.checkbox_animated i.grey.icon {
  color: white !important;
  position: relative;
  right: -2px;
  top: -1px;
}

input#geosuggest__input {
  width: calc(100% - 18px);
}

.geosuggest__suggests-wrapper {
  position: relative;
}

.geosuggest__item {
  font-size: 13px;
  padding: 8px;
  border-bottom: 1px solid #e3e3e3;
  cursor: default;
}

.geosuggest__item:hover {
  background-color: rgb(234, 231, 231);
}

.geosuggest__item--active {
  background: #267dc0 !important;
  color: #fff;
}

.geosuggest__suggests--hidden {
  max-height: 0 !important;
  overflow: hidden !important;
  border-width: 0 !important;
}

.notification {
  opacity: 1 !important;
  position: relative;
  margin-top: 85px !important;
}

.profile_tooltip::before,
.search_tooltip::before {
  background-color: green !important;
}

#jobTitleDropdown {
}

#popper-container {
  left: -11px !important;
  width: 500px !important;
}

#popper-container- {
  left: -11px !important;
}

.rccs {
  margin: 0 !important;
}

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.red {
  color: red;
}

#ai-prompt textarea {
  border-color: black;
  height: 50px;
  font-size: 17px;
}

#ai-prompt i {
  right: 10px;
  bottom: 12px;
}

#editSearchTags .ui.input {
  flex-grow: 1 !important;
}

@media (max-width: 820px) {
  #ai-prompt i {
    right: 10px;
    bottom: 10px;
  }

  .control-dots .dot {
    margin: 0 10px !important;
    width: 12px !important;
    height: 12px !important;
    -webkit-tap-highlight-color: transparent !important;
  }

  .control-dots .dot.selected {
    background-color: rgb(112, 116, 242) !important;
  }

  .control-dots .dot:not(.selected) {
    background-color: gray !important;
  }

  .control-dots {
    bottom: -10px !important;
  }

  .carousel-slider {
    padding-bottom: 25px !important;
  }
}

.sticky-nav-active .hosting_navbar {
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  padding: 26px 0;
}

.reusecore-drawer__handler button {
  min-height: 40px !important;
}

/* React Phone Number Input styles */

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.ui i {
  outline: none;
}

@media (max-width: 1299px) {
  .main_menu {
    display: none;
  }
}

@media (min-width: 821px) {
  .candidateApplications:first-child {
    height: calc(100vh - 80px);
  }
}

.ui.dimmer {
  z-index: 1000000000 !important;
}