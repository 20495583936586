/*
  	Flaticon icon font: Flaticon
  	Creation date: 10/02/2019 12:14
  	*/

      @font-face {
        font-family: 'Flaticon';
        src: url('../assets/fonts/Flaticon.eot');
        src: url('../assets/fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
          url('../assets/fonts/Flaticon.woff2') format('woff2'),
          url('../assets/fonts/Flaticon.woff') format('woff'),
          url('../assets/fonts/Flaticon.ttf') format('truetype'),
          url('../assets/fonts/Flaticon.svg#Flaticon') format('svg');
        font-weight: normal;
        font-style: normal;
      }
      
      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        @font-face {
          font-family: 'Flaticon';
          src: url('../assets/fonts/Flaticon.svg#Flaticon') format('svg');
        }
      }
      
      [class^='flaticon-']:before,
      [class*=' flaticon-']:before,
      [class^='flaticon-']:after,
      [class*=' flaticon-']:after {
        font-family: Flaticon;
        font-style: normal;
      }
      
      .flaticon-flask:before {
        content: '\f100';
      }
      .flaticon-pencil-case:before {
        content: '\f101';
      }
      .flaticon-ruler:before {
        content: '\f102';
      }
      .flaticon-startup:before {
        content: '\f103';
      }
      .flaticon-creative:before {
        content: '\f104';
      }
      .flaticon-briefing:before {
        content: '\f105';
      }
      .flaticon-magnifying-glass:before {
        content: '\f106';
      }
      .flaticon-user:before {
        content: '\f107';
      }
      .flaticon-quotes:before {
        content: '\f108';
      }
      .flaticon-next:before {
        content: '\f109';
      }
      .flaticon-left-arrow:before {
        content: '\f10a';
      }
      .flaticon-plus-symbol:before {
        content: '\f10b';
      }
      .flaticon-minus-symbol:before {
        content: '\f10c';
      }
      .flaticon-trophy:before {
        content: '\f10d';
      }
      .flaticon-conversation:before {
        content: '\f10e';
      }
      .flaticon-atom:before {
        content: '\f10f';
      }
      .flaticon-link:before {
        content: '\f110';
      }
      .flaticon-battery:before {
        content: '\f111';
      }
      .flaticon-stopwatch:before {
        content: '\f112';
      }
      .flaticon-stopwatch-1:before {
        content: '\f113';
      }
      .flaticon-objective:before {
        content: '\f114';
      }
      .flaticon-facebook-logo:before {
        content: '\f115';
      }
      .flaticon-twitter-logo-silhouette:before {
        content: '\f116';
      }
      .flaticon-instagram:before {
        content: '\f117';
      }
      .flaticon-dribble-logo:before {
        content: '\f118';
      }
      .flaticon-tumblr-logo:before {
        content: '\f119';
      }
      .flaticon-youtube:before {
        content: '\f11a';
      }
      .flaticon-google-plus:before {
        content: '\f11b';
      }
      .flaticon-blockchain:before {
        content: '\f11c';
      }
      .flaticon-money-bag:before {
        content: '\f11d';
      }
      .flaticon-wallet:before {
        content: '\f11e';
      }
      .flaticon-security:before {
        content: '\f11f';
      }
      .flaticon-bitcoin:before {
        content: '\f120';
      }
      .flaticon-blockchain-1:before {
        content: '\f121';
      }
      .flaticon-annonymous:before {
        content: '\f122';
      }
      .flaticon-group:before {
        content: '\f123';
      }
      .flaticon-prototype:before {
        content: '\f124';
      }
      .flaticon-code:before {
        content: '\f125';
      }
      .flaticon-vectors:before {
        content: '\f126';
      }
      .flaticon-export:before {
        content: '\f127';
      }
      .flaticon-hourglass:before {
        content: '\f128';
      }
      .flaticon-trophy-1:before {
        content: '\f129';
      }
      .flaticon-upload:before {
        content: '\f12a';
      }
      .flaticon-settings:before {
        content: '\f12b';
      }
      .flaticon-strategy:before {
        content: '\f12c';
      }
      