#wordpress {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  padding: 100px 24px;
}

#wordpress p {
  font-size: 20px;
  /* color: #343d48cc; */
  font-family: Roboto, sans-serif;
  line-height: 1.75;
}

#wordpress h2 {
  font-size: 52px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 0;
  font-family: Avenir,sans-serif;
  line-height: 1.5;
  color: #0f2137;
}

#wordpress h3 {
  margin-top: 0;
  font-family: Avenir,sans-serif;
  font-weight: 500;
  text-align: left;
  line-height: 1.5;  
  letter-spacing: -.025em;
  color: #0f2137;
}

.scrollspy__menu.mobile_menu {
  margin-left: 0 !important;
}


.gblock {
  max-width: unset !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  min-width: 100vw;
}

#wordpress .mobile_menu ul {
  margin-left: 0;
}

#wordpress .mobile_menu ul li a {
  height: 34px !important;
}

.drawer-content-wrapper .drawer-content .mobile_menu li {
    margin-bottom: 35px;
    padding: 6px 0;
    height: 34px;
}

@media (max-width: 767px) {
  .drawer-content-wrapper .drawer-content .mobile_menu li {
    margin-bottom: 25px;
  }
}